import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Timeless Motors Retro Araba Kulübü
			</title>
			<meta name={"description"} content={"Klasik otomobil meraklıları için bir cennet olan Timeless Motors Retro Car Club'a hoş geldiniz."} />
			<meta property={"og:title"} content={"Home | Timeless Motors Retro Araba Kulübü"} />
			<meta property={"og:description"} content={"Klasik otomobil meraklıları için bir cennet olan Timeless Motors Retro Car Club'a hoş geldiniz."} />
			<meta property={"og:image"} content={"https://ferandaturc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ferandaturc.com/img/car-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ferandaturc.com/img/car-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://ferandaturc.com/img/1.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			>
				<Text
					color="#FFFFFF"
					font="--lead"
					width="100%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 12px 0px"
					letter-spacing="2px"
				>
					Retro Cars
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Timeless Motors Retro Araba Kulübü
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="25%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Klasik otomobil meraklıları için bir cennet olan Timeless Motors Retro Car Club'a hoş geldiniz.
Kulübümüz, retro otomobillerin mirasını ve güzelliğini korumaya adanmıştır ve benzer düşünen bireylerin tutkularını ve uzmanlıklarını paylaşabilecekleri bir alan sunmaktadır.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://ferandaturc.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Neden Timeless Motors?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ayrıcalıklı Üyelik - Retro otomobil meraklılarından oluşan bir topluluğa katılın, özel etkinliklere erişim kazanın ve klasik otomobillere olan tutkunuzu paylaşan diğer kişilerle bağlantı kurun.
					<br />
					Düzenli Buluşmalar ve Etkinlikler - Otomobil şovlarına, buluşmalara ve sürüşlere katılın, değerli varlığınızı sergileyin ve başkalarını hayran bırakın.
					<br />
					Bilgi Paylaşımı - Restorasyon, bakım ve çeşitli klasik modellerin tarihi hakkında deneyimli üyelerden bilgi edinin.
					<br />
					Erkek Merkezli Topluluk - Yoldaşlık ve araba sohbetinin benzersiz bir bağ kurma deneyimi yarattığı, ağırlıklı olarak erkeklerden oluşan bir kulüp.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Benzersiz Teklifler
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Restorasyon Atölyeleri - Uzmanlar tarafından yönetilen atölye çalışmaları ve oturumlarla otomobil restorasyon sanatı hakkında bilgi edinin.
					<br />
					<br />
					Vitrin Fırsatları - Retro otomobilinizi kulüp etkinliklerinde, yarışmalarda ve sergilerde sergileyin.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://ferandaturc.com/img/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://ferandaturc.com/img/4.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Timeless Motors'a Katılın
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Timeless Motors Retro Araba Kulübü ile kendinizi retro arabaların dünyasına bırakın.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});